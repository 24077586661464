import { defineStore } from 'pinia'

export const useEntryChatStore = defineStore('entryChatStore', {
  state: () => ({
    list: [],
    selected: {},
  }),

  actions: {
    // 👉 fetch chat category
    async fetchWithEntryId(id: number) {
      return await $api<any>(`/entry_chats/entry/${id}`)
    },
  },
})
